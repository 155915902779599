.fee-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.fee-container.vatDetails {
  color: grey;
}

.totalTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
}

.subtotal-container {
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  .fee-container {
    font-size: 18px;
  }
}
