.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.format-money {
  font-size: 1.6em;
  font-weight: bold;
}

.selectionFooter {
  font-style: italic;
  color: grey;
}

.btn-select-date {
  display: flex;
  justify-content: space-around;
  width: 70%;
  margin: 20px auto 0;
}

.modal-timer-button {
  width: fit-content;
  margin-top: 20px;
}

.modal-date-button,
.modal-no-date-button {
  width: 45%;
  min-width: 45%;
}

.title-modal-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-modal-container * {
  margin: 0;
  padding: 0;
}

.titleName {
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
}

.titleDesc {
  font-size: 0.8em;
  color: #808080;
  font-weight: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-modal-content {
  background-color: transparent !important;
  margin: 0 20px;
  box-shadow: none !important;
}

.ant-modal-header {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom: 1px solid var(--primary-color) !important;
  padding: 20px 0;
  margin: 0 !important;
}

.ant-modal-body {
  background-color: transparent;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  padding: 0 !important;
}

.ant-modal-footer {
  background-color: white;
  margin: 15px 0 50px;
  text-align: left !important;
  border-radius: 10px;
}

.ant-modal-wrap,
.ant-modal-mask {
  z-index: 1030 !important;
}

.modal-filter > .ant-modal-content {
  padding: 0;
  margin: 0;
}

.shop-modal-body,
.modal-body-filter {
  background-color: white;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  padding: 24px;
  margin-bottom: 20px;
  position: initial !important;
}

.modal-body-filter {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.modal-body-filter-title {
  text-align: center;
}

.modal-upselling {
  background-color: white;
  border-radius: 10px !important;
  padding: 24px;
  margin-bottom: 20px;
}

.assemblyTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.assembly-select {
  width: 100%;
  margin-top: 10px;
}

.assembly-section-modal:first-child {
  margin: 0;
}

.assembly-section-modal {
  display: flex;
  margin: 30px 0 0 0;
}

.assembly-section {
  padding-right: 20%;
  width: 60%;
  overflow: hidden;
}

.assembly-section-img {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: initial;
  font-size: 12px;
  cursor: pointer;
  position: relative;
}

.assembly-section-img.fullSize {
  width: 100%;
}

.assembly-section-img img {
  width: 100%;
  margin-bottom: 5px;
  border-radius: 10px;
}

.assembly-section-img .close-fullSize-img {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 2em;
  color: white;
}

.assembly-textArea {
  margin-top: 20px;
  width: 50%;
}

.assembly-textArea textarea {
  min-height: 200px;
  min-width: 350px;
  height: 200px;
  max-height: 200px;
}

.assembly-with-price {
  display: flex;
  align-items: baseline;
}

.assembly-with-price span {
  margin-left: 5px;
  font-size: 12px;
}

.assembly-with-price span::before {
  content: '( ';
}

.assembly-with-price span::after {
  content: ' )';
}

.products-required-warning {
  padding-top: 15px;
  font-size: 40px;
  padding-right: 30px;
  padding-bottom: 15px;
  color: #ff9800;
}

/* PLACEMENT CSS */

.selected-seats-container {
  display: flex;
  flex-wrap: wrap;
}

.selected-seat {
  flex: 1;
}

.break-flex {
  flex-basis: 100%;
  height: 0;
}

.seat-informations {
  flex: 1;
  width: 100%;
  align-items: center;
  padding: 10px;
  box-shadow: 0 0 3px rgb(0 0 0 / 25%);
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
}

.delete-seat {
  color: red;
}

.carret-right {
  border-left: 1px solid grey;
}

.modalTimer {
  background-color: white;
  padding: 20px;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalTimer h2,
.simple-modal h2 {
  text-align: center;
}

.close-button {
  position: absolute;
  right: -12px;
  top: -12px;
  background: var(--primary-color);
  border: none;
  z-index: 2;
}

.close-button:focus,
.close-button:hover,
.close-button:active {
  background: var(--primary-color);
}

.simple-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.intent-modal-footer {
  border-top: 1px solid #f0f0f0;
  text-align: center;
  padding-top: 20px;
}

.intent-modal-wrap .ant-modal-content {
  padding: 0 !important;
}

.intent-collapse .ant-collapse-header {
  margin-bottom: 20px;
}

.intent-collapse .ant-collapse-header-text {
  margin: 0 !important;
  font-weight: bold;
}

.intent-collapse h4 {
  font-weight: bold;
}

.intent-collapse .ant-collapse-header,
.intent-collapse .ant-collapse-content-box {
  padding: 0 !important;
}

.forward-list-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
  align-items: center;
}

.forward-list-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

@media (max-width: 575px) {
  .btn-select-date {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .modal-date-button {
    width: 100%;
  }

  .modal-no-date-button {
    width: 100%;
    margin-top: 10px;
  }
}
