.option-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.option-list-item > div:first-child {
  flex: 5;
}

.option-list-item > div {
  flex: 1;
}

.option-list-item > div:last-child {
  text-align: right;
  font-weight: bold;
}

.option-list-item-title {
  font-weight: bold;
}

.option-list-item-desc {
  margin-top: 5px;
  font-size: 13px;
  color: #0099df;
  display: flex;
  align-items: flex-start;
}

.option-list-item-desc .info-icon {
  font-size: 20px;
  margin-right: 10px;
}

.option-list-item-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
}

.option-list-item-container:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.option-list-item-container.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.option-list-item-container .anticon-delete {
  font-size: 20px;
  color: red;
  margin-left: 10px;
}

.option-list-item-container .anticon-delete[disabled] {
  color: lightgray;
  opacity: 0.7;
  cursor: not-allowed;
}

.option-unit-price {
  margin-right: 20px;
}
