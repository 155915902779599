.other-products-container {
  margin-top: 30px;
}

.exchange-item-container {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.exchange-item-container:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.exchange-item-info {
  display: flex;
  margin-left: 10px;
  flex: 2;
  justify-content: space-between;
}

.exchange-item-name {
  flex: 2;
  font-weight: 500;
  font-size: 18px;
  display: block;
  line-height: normal;
  color: black;
}

.exchange-item-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.exchange-quanity {
  display: flex;
  align-items: center;
}

.exchange-quanity div {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
  width: 100%;
}
