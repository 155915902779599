.footer-react {
  background-color: white !important;
  border-top: 1px solid rgb(0 0 0 / 25%) !important;
  min-height: 50px;
  display: flex;
}

#imageLogofooter {
  width: 200px;
}

#imageLogofooter img {
  width: 100%;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: auto;
}

.footerLinks {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footerLinks a {
  color: grey !important;
  margin: 0 10px;
}

@media (max-width: 575px) {
  .footer-react {
    display: none;
  }

  .footerLinks {
    flex-wrap: wrap;
  }

  .footerContainer {
    flex-direction: column;
    padding: 10px 0;
  }
}
