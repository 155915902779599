.debug-provider-info {
  color: #666;
  font-size: small;
  margin-top: 3ex;
  margin-bottom: 3ex;
  mix-blend-mode: difference;
}

.debug-provider-buttons {
  display: flex;
  justify-content: space-around;
}

.debug-provider-buttons a:hover {
  color: white !important;
}

.basketInfo strong {
  margin-right: 10px;
}

@media (max-width: 575px) {
  .debug-provider-buttons {
    flex-direction: column;
  }
}
