.basket-header-link {
  cursor: pointer;
}

.basket-header-link a,
.cancel-order-button {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: var(--primary-color) !important;
}

.basket-header-link > div {
  color: red !important;
  display: flex;
  align-items: center;
}

.cancel-order-button {
  color: red !important;
  margin-left: 5px;
}

.basket-review-next {
  width: fit-content;
  float: right;
}

.basket-attendee-container {
  padding: 10px !important;
  margin-top: 20px;
}

.basket-attendee-next {
  width: fit-content;
  float: right;
}

.basket-attendee-container {
  margin-bottom: 20px;
  box-shadow: 0 0 4px rgb(0 0 0 / 25%);
  background-color: white;
  border-radius: 10px !important;
  overflow: hidden;
  padding: 20px;
  position: relative;
}

.basket-attendee-container:last-child {
  margin-bottom: 0;
}

.payment-container {
  margin-bottom: 15px;
}

.payment-container-button {
  margin-top: 10px;
}

.payment-methods-container {
  width: 100%;
  padding: 10px;
  margin: 0;
}

.payment-methods-container-list {
  display: flex;
  flex-wrap: wrap;
}

.payment-methods-container-list > .payment-methods-item {
  display: flex;
  align-items: center;
  width: calc((100% / 3) - 10px);
  padding: 0;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 15px;
  height: 100%;
  border: 2px solid transparent !important;
}

.payment-methods-item span.ant-radio + * {
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.payment-methods-item.active {
  border: 2px solid var(--primary-color) !important;
}

.payment-method-image {
  max-width: 60px;
  width: 100%;
  margin: 0 auto;
  aspect-ratio: 1/1;
}

.payment-method-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.payment-checkBox-container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.payment-checkBox-container .ant-form-item {
  margin: 0 !important;
}

.payment-checkBox-container * {
  font-weight: normal;
  margin: 0;
}

.payment-checkbox .ant-checkbox {
  align-self: start !important;
  margin-top: 3px;
}

.validate-container-form {
  width: 100%;
}

.validate-container {
  display: flex;
  flex-wrap: wrap;
}

.html-checkbox-content a {
  display: contents;
}

.payment-error {
  color: red;
}

.empty-form-container {
  min-height: 150px;
}

.basket-attendee-container .ant-collapse-header {
  padding: 0 !important;
}

.payment-methods-container .ant-radio {
  display: none;
}

@media (max-width: 575px) {
  .basket-review-next {
    width: 100%;
  }

  .basket-header-link {
    margin-top: 10px;
  }
}
