.basket-summary-container {
  margin-bottom: 20px;
}

.empty-basket {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.recap-unit-price-container {
  color: rgb(128, 128, 128);
}
