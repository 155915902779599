.shop-btn-add {
  width: 100%;
  min-width: 150px;
  min-height: 35px;
  color: white !important;
  background: var(--primary-color) !important;
  border-radius: 4px !important;
  border: none !important;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}

.shop-btn-add.outline {
  color: var(--primary-color) !important;
  background: white !important;
  border: 2px solid var(--primary-color) !important;
}

.shop-btn-add.outline:hover {
  color: white !important;
  background: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
}

a.ant-btn.ant-btn-button.shop-btn-add {
  padding-top: 6px !important;
}

.shop-btn-add::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  opacity: 0;
  transition: opacity 0.3s;
}

.shop-btn-add > * {
  z-index: 1;
}

.shop-btn-add:hover::after {
  opacity: 0.1;
}

.shop-btn-add[disabled]:hover::after {
  opacity: 0;
}

.shop-btn-add[disabled],
.shop-btn-add[disabled]:hover,
.shop-btn-add[disabled]:focus,
.shop-btn-add[disabled]:active {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

.shop-btn-add:focus {
  outline: 0 !important;
}

.shop-btn-add span.anticon.anticon-left {
  position: absolute;
  left: 10px;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin: 0;
}

@media (max-width: 575px) {
  .shop-btn-add {
    min-height: 40px;
    font-size: 18px;
  }
}
