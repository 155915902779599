.form-item-container {
  margin-bottom: 10px;
}

.ant-form-item {
  margin: 0 !important;
}

.form-item-container .ant-form-item-label {
  min-width: 200px;
  max-width: 70%;
  text-align: left;
  padding-right: 10px;
  padding-bottom: 0;
  white-space: break-spaces;
}

.form-item-container .ant-form-item-control-input-content,
.ant-form-item .ant-form-item-explain-error {
  margin-left: 0;
  margin-block: 5px;
}

.form-item-container .ant-form-item-control-input-content > * {
  min-width: 200px;
}

.birthday-container .ant-form-item-control-input-content {
  display: flex;
}

.birthday-container .ant-form-item-control-input-content > * {
  min-width: auto !important;
}

.birthday-container-input .ant-form-item-control-input-content,
.birthday-container-input .ant-form-item-explain-error {
  margin-left: 0;
}

.birthday-input-containers {
  display: flex;
  gap: 10px;
}

.form-item-container span.ant-radio + * {
  padding-left: 10px !important;
}

.form-item-container .ant-input:hover,
.form-item-container .ant-input-number:hover,
.form-item-container
  .ant-select:not(.ant-select-disabled):hover
  .ant-select-selector,
.form-item-container .ant-picker:hover,
.form-item-container .ant-picker-focused {
  border-color: #9a9a9a !important;
}

.dynFormTitle {
  font-size: 20px;
  margin-bottom: 25px;
}

.dynFormSubTitle {
  font-size: 16px;
  margin-bottom: 25px;
}

.dynFormInfo {
  font-size: 14px;
  margin-bottom: 20px;
}

.form-item-container .ant-form-item-label > label {
  height: auto;
}

.form-item-container .ant-form-item-label > label::after {
  content: none;
}

.form-item-container
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  position: absolute;
  margin-right: 0;
  right: -8px;
}

.markdown-form-helper {
  font-size: 12px;
  color: rgb(128, 128, 128);
}

.markdown-form-helper * {
  margin: 0;
}

.forgotten-type-container {
  color: red;
  display: flex;
  align-items: center;
  font-size: 1.5em;
}

.forgotten-type-container p {
  margin: 0 0 0 5px;
}

.image-cropper-modal .ant-modal-content {
  background-color: white !important;
  margin: 0 20px;
  box-shadow: none !important;
}

.image-cropper-modal .ant-modal-footer {
  margin: 15px 0 0 0 !important;
  text-align: right !important;
}

.upload-file-icon {
  margin-right: 10px;
}

.checkbox-type .ant-checkbox {
  align-self: start !important;
  margin-top: 5px;
}

.checkbox-label p {
  display: flex;
  gap: 5px;
  margin: 0;
}

@media (max-width: 575px) {
  .markdown-form-helper {
    margin-left: 0;
  }

  .form-item-container .ant-form-item-label {
    padding-bottom: 0;
  }

  .form-item-container .ant-form-item-control-input-content,
  .ant-form-item .ant-form-item-explain-error {
    margin-left: 0;
  }

  .form-item-container .ant-form-item-control-input-content > * {
    width: 100%;
    height: 40px;
  }

  .react-tel-input .form-control,
  .ant-select-selector,
  .ant-select-selection-search-input {
    height: 40px !important;
  }
}
