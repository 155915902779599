.ant-notification {
  z-index: 99999 !important;
}

.alert-container {
  margin: 20px 0;
  width: 100%;
}

.message-container {
  margin-top: 20px;
  text-align: center;
}

.message-container h2 {
  font-weight: normal;
  font-size: 24px;
  margin: 0 0 15px 0;
}

.message-container p {
  font-weight: normal;
  font-size: 18px;
  margin: 0;
}
