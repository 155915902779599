@font-face {
  font-family: helvetica_emisys;
  font-weight: 200;
  src: url('./assets/fonts/helvetica_light.woff2') format('woff2');
}

@font-face {
  font-family: helvetica_emisys;
  font-weight: 400;
  src: url('./assets/fonts/helvetica_normal.woff2') format('woff2');
}

@font-face {
  font-family: helvetica_emisys;
  font-weight: 700;
  src: url('./assets/fonts/helvetica_bold.woff2') format('woff2');
}

body {
  width: 100% !important;
  height: auto;
}

body * {
  font-family: helvetica_emisys, sans-serif;
}

.shop-container {
  position: relative;
}

.shop-iframe-container {
  padding: 10px 0;
}

.shop-main-container {
  margin: 0 auto 20px;
  min-height: calc(100vh - 165px);
}

.shop-main-container .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.connectContainer > div {
  width: 100%;
  margin: 0 10px;
  max-width: 450px;
}

.apd {
  font-size: 0.7em;
  color: rgb(128, 128, 128);
  text-align: right;
}

.skeleton-container {
  padding: 10px 20px;
  margin-bottom: 20px;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.ant-select-dropdown {
  z-index: 9999 !important;
}

.ant-spin-dot-item {
  background-color: var(--primary-color) !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color) !important;
  background-color: transparent !important;
}

.ant-radio-inner::after {
  background-color: var(--primary-color) !important;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}

.ant-breadcrumb a:hover {
  color: var(--primary-color) !important;
}

.ant-breadcrumb > span:last-child {
  font-weight: normal;
  color: var(--primary-color) !important;
}

/*_______CSS_RADIO_BUTTON_______*/

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.ant-radio-button-wrapper:hover {
  color: var(--primary-color);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--primary-color);
  background: #fff;
  border-color: var(--primary-color);
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: var(--primary-color);
}

.ant-input,
.ant-input:hover,
.ant-input:focus,
.ant-input:active,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover {
  border-color: #ff4d4f !important;
}

/*_______CSS_HORIZONTAL_MENU_______*/

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open {
  color: var(--primary-color);
  border-bottom: none;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none !important;
}

a {
  text-decoration: none !important;
  transition: none;
}

a:hover {
  text-decoration: none !important;
  color: var(--primary-color) !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: black !important;
}

/*_______CSS_CALENDAR_______*/

.calendar {
  width: 100%;
  padding-bottom: 10px;
}

.react-calendar__tile {
  position: relative;
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
  color: rgba(230, 230, 230, 1) !important;
}

.react-calendar__tile:disabled .calendar-tile-color,
.react-calendar__tile--now .calendar-tile-color {
  display: none;
}

.react-calendar__tile--now:disabled {
  background-color: lightgrey !important;
}

.react-calendar__tile--now {
  background: lightgrey !important;
  color: white;
}

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}

.react-calendar__navigation button[disabled] {
  background-color: transparent !important;
}

.react-calendar__month-view__days__day--weekend {
  color: inherit !important;
}

.react-calendar__tile--active {
  background: var(--primary-color) !important;
  color: white !important;
}

.hiddenOnDesktop {
  display: none;
}

.hiddenOnMobile {
  display: block;
}

.calendar-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 575px) {
  .connectContainer {
    margin-top: 20px;
  }

  .calendar {
    width: 100%;
    min-width: auto;
  }

  .calendar-container {
    flex-direction: column;
  }

  .hiddenOnDesktop {
    display: block;
  }

  .hiddenOnMobile {
    display: none;
  }

  .shop-main-container {
    width: 100%;
  }

  .apd {
    text-align: left;
  }
}
