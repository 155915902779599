.wallpaper-image-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-clip: border-box;
  background-origin: padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  top: 0;
  left: 0;
  z-index: -1;
}
