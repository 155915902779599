.main-header {
  position: sticky !important;
  top: -1px;
  z-index: 9;
  margin-bottom: 20px;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.25) !important;
}

.header-shop-container {
  height: 75px;
  display: flex;
  padding-left: 20px;
  background: white;
}

.header-shop-container .ant-menu-submenu {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  border-bottom-style: solid !important;
  border-bottom-width: 2px !important;
  border-bottom-color: transparent !important;
  transition: border-bottom-color 0.2s;
}

.header-shop-container .ant-menu-submenu-disabled {
  display: none;
}

.header-shop-container .ant-menu-submenu:after {
  content: none !important;
}

.header-shop-container .ant-menu-submenu:hover {
  border-bottom-color: var(--primary-color) !important;
}

.header-shop-container .ant-menu-item {
  margin: 0 !important;
  height: 100% !important;
  border: none;
}

.header-shop-container .ant-menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.header-shop-container .item-menu-header.menu-header-item {
  padding: 0 20px !important;
  cursor: default;
  color: initial !important;
  border-bottom: none !important;
  display: flex;
}

.header-shop-container .item-menu-header.menu-header-item:hover {
  color: initial !important;
  border-bottom: none !important;
}

.logo-event {
  cursor: pointer;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.header-loading-icon {
  color: var(--primary-color);
  font-size: 24px;
}

.logo-event-container {
  height: 100%;
  width: 100%;
  max-width: 180px;
  padding: 5px 0;
}

.reseller-banner {
  padding: 10px 0;
  background: var(--emisys-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media (max-width: 575px) {
  .logo-event-container {
    max-width: 35%;
  }
}
