.dark {
  .global-container,
  .category-item,
  .header-shop-container,
  .header-shop-container .ant-menu,
  .ant-drawer-content,
  .footer-react,
  .product-item-card,
  .ant-skeleton,
  .basket-attendee-container {
    background-color: #232d3f !important;
    color: white;
    border: none !important;
  }

  .separatorContainer > span,
  .ant-float-btn-body {
    background-color: #232d3f !important;
  }

  .productHeader-filters,
  .ant-collapse-header,
  .product-name,
  .product-name-container,
  .current-price,
  .basket-item-price,
  .auth-info-text,
  .ant-form-item-control-input,
  .title-modal-container,
  .drawer-title-container > button,
  .footerLinks a,
  .basket-drawer-title,
  .breadcrumb-item.disabled,
  .remember-me-container,
  .form-item-container label,
  .dynFormTitle,
  .payment-checkBox-container label {
    color: white !important;
  }

  .shop-btn-add,
  .quantity-button > span {
    color: #232d3f !important;
  }

  .quantity-container {
    background: #515a6f;
    color: white;
  }

  .paymentMethod img {
    border-radius: 5px;
  }

  .quantity-button[disabled] > span {
    color: gray;
  }

  .shop-btn-add[disabled] {
    background: #707788 !important;
    color: #f5f5f5 !important;
    opacity: 0.2;
  }

  .payment-methods-item.active {
    border: 2px solid var(--primary-color) !important;
  }

  #imageLogofooter {
    filter: brightness(0) invert(1);
  }
}
