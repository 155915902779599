.breadcrumb-container {
  margin-bottom: 20px;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.breadcrumb-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  cursor: pointer;
  gap: 10px;
}

.breadcrumb-item.disabled {
  color: grey;
  cursor: default;
  opacity: 0.5;
}

.breadcrumb-item.visited {
  color: var(--primary-color);
}

.breadcrumb-item.active {
  font-weight: bold;
  color: var(--primary-color);
  opacity: 1 !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: none;
}

@media (max-width: 575px) {
  .breadcrumb-container {
    margin-top: 20px;
  }

  .breadcrumb-item.disabled,
  .breadcrumb-item.visited {
    display: none;
  }
}
