.drawer-calendar {
  flex: 1;
  width: 100% !important;
  box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
  border: none;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.drawer-calendar .react-calendar__viewContainer {
  flex: 1;
}

.drawer-calendar .react-calendar__month-view,
.drawer-calendar .react-calendar__month-view > div {
  height: 100%;
}

.drawer-calendar .react-calendar__month-view > div > div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.drawer-calendar .react-calendar__month-view__days {
  height: 100%;
}

.calendar-reference {
  display: flex;
  flex: 1;
  margin: 15px 0 0 0;
  flex-direction: row;
  flex-wrap: wrap;
}

.color-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.colorBox {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  margin-right: 7px;
  background-color: var(--primary-color);
}

.color-text-container {
  display: flex;
  flex-direction: column;
}

.schedule {
  display: flex;
  color: grey;
}

.calendar-tile-color {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 3px;
}

/* DRAWER CALENDAR CSS*/

.drawer-calendar-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.drawer-calendar-container .calendar {
  flex: 1;
  border: none;
  box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
  border-radius: 10px;
}

.drawer-calendar-schedule {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 0 0 20px;
  min-height: 380px;
}

.drawer-calendar-schedule.hidden {
  display: none;
}

.drawer-calendar-schedule.hiddenCalendar {
  margin: 0;
  min-height: auto;
}

@media (max-width: 1110px) {
  .drawer-calendar-container {
    flex-direction: column;
  }

  .drawer-calendar-schedule {
    margin: 20px 0 0 0;
    min-height: auto;
  }
}
