.multipleChoiceTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.session-radio-container,
.option-radio-container {
  display: flex;
  flex-direction: column;
}

.option-button-container {
  margin: 20px 0;
}

.option-select-container {
  width: 40%;
  margin-bottom: 20px;
}

.option-container {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}

.option-container > div {
  white-space: pre-line;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.option-container .description {
  width: 50%;
}

.description p {
  margin: 0;
}

.radio-button-item {
  margin: 10px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

span.ant-radio + * {
  padding-left: 22px !important;
}

.radio-button-item.ant-radio-wrapper-checked {
  font-weight: bold;
}
