.overlap-iframe::before {
  content: '';
  background: black;
  opacity: 0.8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.overlap-iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  animation-duration: 0.5s;
  animation-name: showOverlap;
}

@keyframes showOverlap {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlap-iframe-close {
  color: white;
  font-size: 2em;
  position: absolute;
  top: 10px;
  right: 10px;
}

.overlap-iframe-container {
  text-align: center;
  z-index: 0;
}

.overlap-iframe-logo img {
  filter: brightness(0) invert(1);
}

.overlap-iframe-info {
  color: white;
  font-size: 18px;
}

.overlap-iframe-link {
  color: white;
  text-decoration: underline !important;
  font-weight: bold;
  cursor: pointer;
}
