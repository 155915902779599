.current-price {
  color: #474747;
  font-weight: normal;
  font-size: 18px;
  margin-top: 10px;
}

.first-price {
  color: darkgrey;
  margin: 5px 5px 0;
  text-decoration: line-through;
}

@media (max-width: 575px) {
  .current-price {
    font-size: 20px;
  }
}
