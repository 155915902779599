.ant-modal-body {
  position: relative;
}

.ant-input:hover {
  border-color: grey !important;
  border-right-width: 1px !important;
}

textarea.ant-input:hover {
  border-color: #d9d9d9 !important;
}

.ant-btn-link:hover {
  color: white !important;
  filter: brightness(95%);
}

/*_______CSS_MODAL_______*/

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 9999;
}

/*_______CSS_UPLOAD_______*/

.ant-upload-list-item {
  padding: 0 !important;
  overflow: hidden;
}

.ant-upload-list-item::before {
  width: 100% !important;
  height: 100% !important;
}

/*_______CSS_HORIZONTAL_MENU_______*/

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  padding: 0 15px !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  display: none;
}

/*_______CSS_SPIN_______*/

a {
  display: block;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: initial !important;
}

/*_______CSS_RADIO_BUTTON_______*/

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}

.ant-radio-button-wrapper {
  margin-right: 10px;
  border-radius: 5px !important;
  border: 1px solid rgb(230, 230, 230);
  transition: color 0.3s;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.ant-breadcrumb > span:last-child {
  font-weight: normal;
}

/*_______CSS_CALENDAR_______*/

.calendar {
  width: 100%;
  padding-bottom: 10px;
}

.react-calendar__tile {
  position: relative;
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
  color: rgba(230, 230, 230, 1) !important;
}

.react-calendar__tile:disabled .calendar-tile-color,
.react-calendar__tile--now .calendar-tile-color {
  display: none;
}

.react-calendar__tile--now:disabled {
  background-color: lightgrey !important;
}

.react-calendar__tile--now {
  background: lightgrey !important;
  color: white;
}

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}

.react-calendar__navigation button[disabled] {
  background-color: transparent !important;
}

.react-calendar__month-view__days__day--weekend {
  color: inherit !important;
}

.react-calendar__tile--active {
  background: var(--primary-color) !important;
  color: white !important;
}

@media (max-width: 575px) {
  .calendar {
    width: 100%;
    min-width: auto;
  }
}
