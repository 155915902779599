.global-container {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  position: relative;
}

.global-container[disabled] {
  box-shadow: none;
  background-color: rgb(220, 220, 220);
  border-color: rgb(220, 220, 220) !important;
  cursor: not-allowed;
}

.global-container[disabled]:after {
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: white;
  opacity: 0.8;
}

@media (max-width: 575px) {
  .global-container {
    padding: 15px;
  }
}
