.drawer-body-session {
  padding-bottom: 10px;
}

.selected-seats-container {
  margin-top: 10px;
  gap: 8px 12px;
}

.product-drawer-image {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.product-drawer-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.drawer-title-container {
  display: flex;
}

.selection-info {
  display: flex;
  justify-content: space-between;
}

.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.format-money {
  font-size: 1.6em;
  font-weight: bold;
}

.selectionFooter {
  font-style: italic;
  color: grey;
}

.product-drawer-container {
  z-index: 9999;
}

.product-drawer-container .ant-spin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-drawer-container .ant-drawer-content-wrapper {
  width: 70vw !important;
  height: calc(100% - 10px);
  bottom: 0;
  top: 10px;
}

.product-drawer-container.iframe-drawer .ant-drawer-content-wrapper {
  width: 98vw !important;
  height: 100%;
}

.product-drawer-container .ant-drawer-content {
  border-radius: 10px 0 0 0;
}

.confirm-drawer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 10px 0 15px 0;
}

.session-schedule-container {
  margin: 0 auto;
  align-content: center;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  font-weight: bold;
}

.session-schedule-container.alone {
  grid-template-columns: repeat(1, 1fr);
  margin: 0 50px;
}

.session-schedule-container > * {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 40px;
  min-width: max-content;
}

.drawer-total-price {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 10px;
}

.empty-session-list {
  padding: 20px 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: inherit;
  font-size: 16px;
  color: #969696;
}

.go-top-sessions-selected {
  display: flex;
  justify-content: center;
  align-content: center;
  color: var(--primary-color);
}

.go-top-sessions-selected-arrow {
  animation: MoveUpDown 1.5s cubic-bezier(0.52, 1.04, 1, 1) infinite;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 10px;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: -30px;
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(+10px);
  }
}

#sessions-list-selected,
.sessions-list-chosen {
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .product-drawer-container .ant-drawer-content-wrapper {
    width: 80vw !important;
    height: 100%;
  }
}

@media (max-width: 575px) {
  .selection-info {
    flex-direction: column;
  }

  .product-drawer-container .ant-drawer-content-wrapper {
    width: 98vw !important;
  }

  .confirm-drawer-container {
    flex-direction: row;
    align-items: stretch;
    text-align: center;
  }

  .confirm-drawer-container > div {
    flex: 1;
  }

  .drawer-btn-add-to-basket {
    margin-top: 10px;
    flex-grow: 1.5 !important;
  }

  .drawer-btn-add-to-basket > button {
    height: 100%;
  }

  .session-schedule-container > * {
    min-height: 55px;
  }
}
