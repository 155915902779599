.order-list-item-container,
.order-detail-item-container,
.order-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}

.order-item-header {
  align-items: flex-start;
  border: none;
}

.order-list-item-container:last-child,
.order-item-payment:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.order-item-payment:last-child {
  font-size: 1.5em;
}

.order-list-item-left {
  display: flex;
  flex-direction: column;
}

.order-detail-item-info li {
  color: darkgrey;
  margin-bottom: 5px;
  font-style: italic;
}

.order-list-item-id {
  font-size: 1.2em;
}

.order-list-item-date {
  font-size: 1.1em;
  color: grey;
  margin-top: 5px;
}

.order-list-item-right {
  display: flex;
  align-items: center;
}

.order-list-item-price {
  margin-right: 30px;
  font-size: 1.5em;
}

.order-list-item-button-container {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid var(--border-color);
}

.order-list-item-detail-button {
  width: 40px !important;
  min-width: 40px !important;
  height: 40px !important;
  font-size: 20px !important;
}

.order-detail-item-quantity {
  text-align: right;
  font-size: 20px;
  color: grey;
}

.order-detail-item-price {
  text-align: right;
}

.order-detail-item-unit-price {
  font-size: 1.3em;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 10px;
}

.order-detail-item-full-price {
  color: grey;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 5px;
}

.order-detail-item-download {
  margin-top: 10px;
  gap: 10px;
  display: flex;
  justify-content: flex-end;
}

.order-more-action {
  padding: 0 8px;
  border-radius: 4px !important;
  min-height: 35px;
}

.order-item-info-container {
  display: flex;
  padding-top: 10px;
}

.order-item-payment-method {
  flex: 3;
  font-size: 1.5em;
  font-weight: bold;
}

.order-item-payment-method figure {
  margin: 20px 0 0 0;
  display: flex;
  gap: 10px;
}

.order-item-payment-method img {
  width: 15%;
}

.order-item-payment-info {
  flex: 2;
}

.order-item-payment {
  display: flex;
  justify-content: space-between;
  font-size: 1.3em;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
}

.order-item-payment span:first-child {
  font-weight: bold;
}

.order-warning-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
}

.order-warning-container span {
  font-size: 2em;
  color: #ff9800;
}

.order-warning-container h3 {
  font-weight: bold;
  margin-bottom: 5px;
}

.order-warning-container p {
  color: #444444;
  margin-bottom: 5px;
}

.order-detail-item-left h2 {
  margin: 0;
}

@media (max-width: 575px) {
  .order-list-container {
    margin-top: 20px;
  }

  .order-list-item-container,
  .order-detail-item-container,
  .order-item-info-container {
    flex-direction: column;
  }

  .order-list-item-left {
    text-align: center;
    margin-bottom: 20px;
  }

  .order-list-item-right {
    width: 100%;
    justify-content: space-between;
  }

  .order-item-payment-method {
    text-align: center;
    margin-bottom: 20px;
  }

  .order-detail-item-left {
    width: 100%;
  }

  .order-detail-item-right {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .order-more-action {
    min-height: 40px;
    font-size: 18px;
    padding: 0 10px;
  }
}
