.title-container {
  margin-bottom: 20px;
}

.title-container > h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  margin-top: 0;
}

.title-container > hr {
  width: 100%;
  height: 1px;
  background: var(--primary-color);
  border: var(--primary-color);
  margin: 0;
}

.title-container .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title-container h3 {
  margin: 0;
}
