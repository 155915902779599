.success-error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

@supports (
  animation: growSuccess 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards
) {
  .success-tick {
    stroke-opacity: 0;
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    animation-delay: 0.6s;
  }

  .success-circle {
    fill-opacity: 0;
    stroke: #219a00;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: growSuccess 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  }
}

@supports (
  animation: growError 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards
) {
  .error-cross {
    stroke-opacity: 0;
    stroke-dasharray: 40px;
    stroke-dashoffset: 40px;
    animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    animation-delay: 0.6s;
  }

  .error-circle {
    fill-opacity: 0;
    stroke: #ff3939;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: growError 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  }
}

@keyframes growSuccess {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #219a00;
  }
}

@keyframes growError {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #ff3939;
  }
}

@keyframes draw {
  0%,
  100% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
