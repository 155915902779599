.timer-aside {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.timer-aside-container {
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}

.timer-aside-container span {
  font-size: 2em;
  color: #ff9800;
}

.timer-left-title {
  flex: 1;
}

.timer-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer {
  font-style: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.timer > span {
  margin: 0;
  font-size: 16px !important;
  margin-inline-start: 0 !important;
}

.icon-info-circle {
  margin: 0 0 0 10px !important;
  color: var(--primary-color);
}
