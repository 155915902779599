.header-container {
  padding: 10px 20px;
  margin-bottom: 20px;
}

.basket-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basket-header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
}

@media (max-width: 575px) {
  .basket-header-title {
    text-align: center;
  }

  .header-container {
    border-radius: 0;
    margin: 0 -8px;
  }

  .basket-header {
    flex-direction: column;
  }
}
