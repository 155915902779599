.cookies-container {
  width: 95% !important;
  left: 50% !important;
  transform: translate(-50%, -30%);
  padding: 0 !important;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  max-width: 1140px;
  background: white !important;
  color: black !important;
  align-items: center !important;
}

.cookie-accept-button,
.cookie-decline-button {
  min-width: 150px;
  min-height: 35px;
  color: white !important;
  background: var(--primary-color) !important;
  border-radius: 4px !important;
  border: none !important;
}

.cookie-decline-button {
  color: var(--primary-color) !important;
  background: white !important;
  border: 2px solid var(--primary-color) !important;
}

.cookie-buttons-container {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .cookies-container {
    text-align: center;
  }

  .cookie-buttons-container {
    width: 100%;
    justify-content: center;
  }
}
