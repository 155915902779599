.title-my-account {
  max-width: calc(100% - 50px);
  margin-left: 10px;
  white-space: normal;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-menu-header .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 0 20px !important;
  border-left: 2px solid #f3f3f3;
  border-radius: 0;
}

.dropdown-menu-header .ant-menu-submenu-title:hover {
  color: var(--primary-color) !important;
}

.dropdown-menu-header.account {
  color: var(--primary-color);
  width: 200px;
}

li.ant-menu-item.ant-menu-item-only-child:hover,
li.ant-menu-item.ant-menu-item-only-child:hover a,
li.ant-menu-item.ant-menu-item-only-child a:hover {
  color: var(--primary-color) !important;
}

.avatar-my-account {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu-header-popup {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
