.paymentButtons {
  margin-top: 15px;
  margin-bottom: 7ex;
  text-align: center;
}

.payconiqModal > .ant-modal-content {
  padding: 0;
}

.payconiqBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  padding: 0 10px;
  border-radius: 10px;
}
.payconiqBox .payconiqFrame {
  margin-top: -20px;
  text-align: center;
  color: #fff;
  position: relative;
  border-radius: 18px;
  background-color: #ff4785;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 30%);
  background-image: linear-gradient(290deg, #ff4785 65%, #f04071 35%);
  background-position: right top;
  font-size: 2.25rem;
  font-family: Sofia Pro, Arial, sans-serif;
  font-weight: bold;
  line-height: 1.5;
}

.payconiqLogo img {
  width: 100%;
}

.payconiqBox .payconiqTitle {
  font-size: 1.5rem;
  margin: 25px;
  color: white;
}

.payconiqBox .payconiqAmount {
  font-size: 4rem;
}

.payconiqBox .payconiqPayLink {
  margin-bottom: 20px;
}

.payconiqBox .payconiqPayLink a {
  text-decoration: underline !important;
  color: white;
  font-weight: normal;
  font-size: 13px;
}

.qrCode {
  margin: 1em;
}

.close-payconiq-btn {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
  font-size: 25px;
}
