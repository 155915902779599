.promoCodeContainer {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.promoInputButtonContainer {
  display: flex;
  margin: 10px 0 5px;
  align-items: center;
}

.inputPromoContainer {
  width: 100%;
  margin: 0 10px 0 0;
}

.promoCodeMessageError {
  color: red;
}

.promoCodeMessageSuccess {
  color: green;
}

.addPromoCodeButton {
  width: 140px;
  min-width: 100px;
}

@media (max-width: 575px) {
  .promoInputButtonContainer {
    flex-direction: column;
  }

  .inputPromoContainer {
    margin: 0;
  }

  .addPromoCodeButton {
    width: 100%;
    margin-top: 10px;
  }
}
