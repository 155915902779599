.upsellingItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.upsellingItemContainer:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.upsellingItemContainerName {
  width: 100%;
}

.upsellingItemName {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.upsellingItemContainerQty {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.upselling-item-info {
  margin-right: 10px;
  margin-left: 10px;
}

@media (max-width: 575px) {
  .upsellingItemContainer {
    flex-direction: column;
  }

  .upsellingItemContainerQty {
    flex-direction: column;
  }

  .upsellingItemName {
    font-size: 24px;
  }

  .upselling-item-info {
    margin-top: 15px;
  }

  .upsellingItemContainerName {
    display: flex;
    justify-content: center;
  }
}
