.barcode-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
}

.user-ticket-item-container {
  display: flex;
  width: calc((100% - 30px) / 3);
  flex-direction: column;
  justify-content: space-between;
}

.user-ticket-item-container.disabled {
  opacity: 0.5;
}

.user-ticket-item-info-container {
  display: flex;
  flex: 1;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.user-ticket-item-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.user-ticket-item-download {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  flex: 1;
}

.user-ticket-item-action {
  padding: 0 8px;
  border-radius: 4px !important;
  min-height: 35px;
}

.user-ticket-item-info {
  flex: 1;
  margin-bottom: 10px;
  min-height: 150px;
}

.user-ticket-item-title {
  display: flex;
  align-items: baseline;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user-ticket-item-title h2 {
  margin: 0;
}

.forward-alert {
  margin-block: 20px;
  padding: 10px 14px;
}

.user-ticket-item-transferred {
  font-size: 15px;
  font-style: italic;
}

.user-assign-ticket-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-assign-ticket-container > div {
  max-width: 575px;
}

.user-assign-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.see-barcode-placement {
  color: var(--primary-color);
  cursor: pointer;
}

.see-barcode-placement:hover {
  text-decoration: underline;
}

.wallet-btn img {
  height: 35px;
}

.barcode-info-container {
  font-size: 16px;
  color: #333333;
}

.barcode-info-container > div {
  margin-bottom: 15px;
}

.barcode-info-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.barcode-date-time {
  font-style: italic;
  color: #666666;
}

.QR-code-container.disabled {
  opacity: 0.1;
}

@media (max-width: 768px) {
  .user-ticket-item-container {
    width: calc((100% - 15px) / 2);
  }
}

@media (max-width: 575px) {
  .barcode-list-container {
    margin-top: 20px;
  }

  .user-ticket-item-container {
    width: 100%;
  }

  .user-ticket-item-action {
    min-height: 40px;
    font-size: 18px;
    width: 100%;
  }

  .wallet-btn img {
    height: 40px;
  }

  .user-ticket-item-info {
    min-height: 100px;
  }

  .user-ticket-item-actions {
    flex-direction: column-reverse;
    align-items: center;
  }

  .user-ticket-item-download {
    width: 100%;
    align-items: center;
  }
}
