.basket-items-container {
  padding-bottom: 15px;
}

.basket-item-container {
  border-bottom: 1px solid var(--border-color);
  padding: 15px 0;
}

.basket-item-container:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.basket-item-side {
  display: flex;
  align-items: baseline;
}

.basket-item-side > div {
  flex: 1;
}

.delete-item-button {
  color: red;
  margin-right: 10px;
}

.basket-item-info:first-child {
  margin-top: 0;
}

.basket-item-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.basket-name {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  line-height: initial;
  font-size: 16px;
}

.basket-name div {
  font-size: 0.9em;
  color: grey;
  padding-left: 10px;
}

.basket-item-price {
  color: black;
  font-weight: bold;
  font-size: 16px;
}

.basket-quantity {
  margin-right: 5px;
}

@media (max-width: 575px) {
  .delete-item-button,
  .basket-item-price,
  .basket-name {
    font-size: 20px;
  }

  .basket-item-info {
    font-size: 18px;
  }
}
