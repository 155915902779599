.paymentMethod-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

.paymentMethod-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.paymentMethod {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.paymentMethod img {
  margin: 0;
  width: 20%;
}

@media (max-width: 575px) {
  .paymentMethod img {
    width: 10%;
  }
}
