.basketInfo {
  text-align: center;
}

.basketInfo > span:first-child {
  font-weight: lighter;
  margin-right: 10px;
  font-size: 18px;
}

.basketInfo > span:last-child {
  font-weight: bold;
  font-size: 18px;
}

.squareup-modal > .ant-modal-content {
  padding: 0;
  margin: 0;
}

#rswp-card-button {
  background-color: var(--primary-color);
}
