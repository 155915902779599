.session-list-item {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
}

.option-list-item-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.option-list-item-header > div {
  flex: 1;
}

.session-list-item > div {
  flex: 2;
}

.session-list-item > div:last-child {
  flex: 1;
  text-align: right;
  font-weight: bold;
}

.session-schedule-title {
  font-weight: normal;
  font-size: 20px;
  margin: 0 -20px 30px;
  padding: 0 0 20px 0;
  text-align: center;
  box-shadow: 0px 7px 10px rgb(0 0 0 / 5%);
}

.session-filter-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.session-filter-container > div {
  width: calc((100% / 3) - 10px);
  margin-right: 10px;
  min-width: 200px;
  margin-bottom: 20px;
}

.filter-session .ant-select-selector {
  border-radius: 5px !important;
  height: 40px;
  border-color: lightgrey !important;
}

.filter-tag {
  margin-right: 3px;
  background: #ffffff;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  padding: 2px 10px;
  font-size: 14px;
}

.filter-tag > span {
  color: var(--primary-color);
  font-size: 14px;
}

.session-option-container {
  box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}

.session-option-panel .ant-collapse-header {
  align-items: center !important;
  padding: 20px !important;
}

.session-option-panel .ant-collapse-header-text {
  width: 100%;
}

.collapse-button {
  width: auto;
  padding: 0 20px !important;
  font-size: 14px !important;
  position: relative !important;
  float: right;
  right: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  top: 0 !important;
  transform: none !important;
}

.back-page-sessions-selection {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.next-page-sessions-selection {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.session-schedule-container {
  margin-left: 50px;
  margin-right: 50px;
}

.container-sessions-selection {
  display: flex;
  height: 100%;
}

.button-timeslot-session-bottom-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-timeslot-session-bottom {
  width: 50%;
}

.back-icon-session-selection {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.next-icon-session-selection {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.session-date-timeslot-selected {
  display: flex;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 15px;
  text-align: center;
}

.container-session-day-selected {
  flex: 2;
}

.container-session-with-qty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  align-content: flex-start;
}

.container-session-without-qty {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-content: flex-start;
}

.session-qty-remaining {
  margin-top: -10px;
  font-weight: lighter;
  color: #7a7a7a;
}

.session-list-item-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.session-list-item-container .anticon-delete {
  font-size: 20px;
  color: red;
  margin-left: 10px;
}

.timeslot-hour-split {
  font-size: 10px;
  color: var(--primary-color);
}

.list-item-qty-remaining {
  color: grey;
}

.session-list-item-mobile-container {
  display: none;
}

.session-details-mobile {
  display: flex;
}

.session-details-price-mobile {
  flex: 1;
  flex-grow: 2;
}

.session-details-quantity-mobile {
  flex: 1;
}

.option-list-item-header-mobile {
  display: none;
}

.collapse-button-show-options .show-icon {
  display: flex;
  margin-left: 5px;
}

@media (max-width: 1110px) {
  .session-list-item-container {
    display: none;
  }

  .session-list-item-mobile-container {
    display: flex;
    margin-bottom: 10px;
  }

  .session-list-item-mobile {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .session-details-mobile > span:first-child {
    font-weight: bold;
    margin-right: 5px;
  }

  .option-list-item-header-mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .option-list-item-header {
    display: none;
  }

  .session-options-item-header-mobile > span:first-child {
    font-weight: bold;
    margin-right: 5px;
  }
}

@media (max-width: 575px) {
  .session-option-panel .ant-collapse-header {
    flex-direction: column;
  }

  .next-icon-session-selection {
    padding: 8px;
  }

  .back-icon-session-selection {
    padding: 8px;
  }

  .session-filter-container > div {
    width: 100%;
  }
}
