.product-icon {
  font-size: 25px;
  color: var(--secondary-color);
}

.product-icon-dates {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-icon-dates span {
  font-size: 0.8em;
  font-weight: bold;
  line-height: initial;
  color: var(--secondary-color);
}

.dates-container {
  display: flex;
  align-items: baseline;
}

.between-dates-icon {
  font-size: 0.4em !important;
}

.product-icon-container {
  width: 70px;
  height: 70px;
  color: white;
  overflow: hidden;
}

.product-icon-container img {
  height: 100%;
}

.icon-container,
.images-container {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.icon-container {
  border-right: 1px solid var(--secondary-color);
}

.images-container {
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 575px) {
  .product-icon-container {
    display: none;
  }
}
