.loading-shop-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(240, 240, 240, 0.3);
}

.loading-shop-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.loading-shop-lazy img {
  opacity: 0.05;
}

.emisys-star {
  width: 100px;
  height: 100px;
  transform-origin: center;
  animation: emisysStar 4s infinite;
}

.loading-shop-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-shop-error-container > div {
  color: red;
  margin-bottom: 20px;
}

.loading-shop-button {
  background-color: #ff00a0 !important;
  color: white !important;
}

@keyframes emisysStar {
  20% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.67, 0.2);
  }
  50% {
    transform: rotate(540deg);
    animation-timing-function: cubic-bezier(0.21, 0.6, 0.35, 1);
  }
  100% {
    transform: rotate(1080deg);
  }
}
