.preview-banner-container {
  position: sticky;
  top: 0;
  height: 80px;
  padding: 0 40px;
  background: #fff;
  box-shadow: 0 2px 18px 0 rgba(129, 162, 182, 0.2);
  z-index: 9;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.preview-banner-container img {
  height: 50px;
}

.info-preview-banner {
  flex: 1 1;
  text-align: center;
  font-size: 1.2em;
  color: rgb(128, 128, 128);
}

.preview-banner-button {
  background: #ff00a0 !important;
  width: fit-content !important;
}
