.quantity-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 0 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  overflow: hidden;
  background: white;
}

.quantity-container-loading-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer-events-none {
  pointer-events: none;
}

.quantity-container.vertical {
  flex-direction: column-reverse;
  width: 100%;
}

.quantity-container.vertical .quantity-button {
  width: 100% !important;
}

.quantityItem {
  min-width: 30px;
  margin: 0 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  flex: 1;
}

.quantity-button:disabled,
.quantity-button:disabled:focus,
.quantity-button:disabled:hover,
.quantity-button:disabled:active {
  background: rgba(0, 0, 0, 0.04) !important;
}

.quantity-button,
.quantity-button:focus,
.quantity-button:hover,
.quantity-button:active {
  width: 30px !important;
  height: 30px !important;
  padding: 0;
  color: white !important;
  background: var(--primary-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  border-radius: 0;
}

.quantity-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  opacity: 0;
  transition: opacity 0.3s;
}

.quantity-button[disabled]:hover {
  background: none;
}

.quantity-button > * {
  z-index: 1;
}

.quantity-button:hover::after {
  opacity: 0.1;
}

.quantity-container .ant-input-number {
  margin: 0 10px;
  width: 50px;
}

.quantity-container .ant-input-number-handler-wrap {
  display: none;
}

.quantity-button-less,
.quantity-button-more {
  flex: 1;
}

.quantity-loader-container {
  width: 50px;
}

@media (max-width: 575px) {
  .quantity-button,
  .quantity-button:focus,
  .quantity-button:hover,
  .quantity-button:active {
    width: 45px !important;
    height: 40px !important;
  }
}
